import instance from "./api/api_instance";

export const getAllEvents = async (credentials: object) => {
  const response = await instance.get("EventType/GetAll", {
    params: credentials,
  });
  return response.data;
};

export const addEventType = async (credentials: object) => {
    const response = await instance.post('EventType/AddUpdate', credentials);
    return response.data;
  };

  export const getAllCities = async (credentials: object) => {
    const response = await instance.get("City/GetAll", {
      params: credentials,
    });
    return response.data;
  };

  export const getAllCitiesById = async (credentials: object) => {
    const response = await instance.get("City/GetAll", {
      params: credentials,
    });
    return response.data;
  };

  export const addCity = async (credentials: object) => {
    const response = await instance.post('City/AddUpdate', credentials);
    return response.data;
  };

  export const getAllCountries= async (credentials: object) => {
    const response = await instance.get("Country/GetAll", {
      params: credentials,
    });
    return response.data;
  };

  export const addCountry = async (credentials: object) => {
    const response = await instance.post('Country/AddUpdate', credentials);
    return response.data;
  };
  export const getCountryById = async (id: number | string) => {
    const response = await instance.get(`Country/GetById`,
       {
        params: {
          id:id
        },
      }
    );
    return response.data;
  };

  export const getEventTypeById = async (id: number | string) => {
    const response = await instance.get(`EventType/GetById`,
       {
        params: {
          id:id
        },
      }
    );
    return response.data;
  };

  export const getAllCategories = async (credentials: object) => {
    const response = await instance.get("Category/GetAll", {
      params: credentials,
    });
    return response.data;
  };

  export const getAllSubCategories = async (credentials: object) => {
    const response = await instance.get("Category/GetAllSubCategory", {
      params: credentials,
    });
    return response.data;
  };

  export const addCategory = async (formData: FormData) => {
    const response = await instance.post(
      "Category/AddUpdateCategory",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  export const getCategoryById = async (id: number | string) => {
    const response = await instance.get(`Category/GetById`,
       {
        params: {
          id:id
        },
      }
    );
    return response.data;
  };

// export const changeCustomerStatus = async (credentials: object) => {
//     const response = await instance.post('AppUser/ChangeUserStatus', credentials);
//     return response.data;
//   };

export const getAllParentCategory = async (credentials: object) => {
  const response = await instance.get("Category/GetParentCategoryList", credentials);
  return response.data;
};

export const getSubCategoryList = async (credentials: object) => {
  const response = await instance.get("Category/GetSubCategoryList", {
    params: credentials,
  });
  return response.data;
};

export const getCityById = async (credentials: object) => {
  const response = await instance.get("City/GetCitiesByCountryId", {
    params: credentials,
  });
  return response.data;
};


export const getAllTermsCondition = async (credentials: object) => {
  const response = await instance.get("TermAndCondition/GetAll",{});
  return response.data;
};

export const addTermAndCondition = async (credentials: object) => {
  const response = await instance.post('TermAndCondition/UpdateTermAndCondition', credentials);
  return response.data;
};

export const getAllCancelationPolicy = async (credentials: object) => {
  const response = await instance.get("CancelationPolicy/GetAll",{});
  return response.data;
};

export const updateCancellationPolicy = async (credentials: object) => {
  const response = await instance.post('CancelationPolicy/UpdateCancelationPolicy', credentials);
  return response.data;
};