import instance from "./api/api_instance";

export const adminServiceCreateUpdate = async (credentials: FormData) => {
    const response = await instance.post(
      "AdminService/CreateUpdate",
      credentials,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };
  
  export const getAllServices = async (credentials: object) => {
    const response = await instance.get("AdminService/GetAllServices", {
      params: credentials,
    });
    return response.data;
  };

  export const getAllPackage = async (credentials: object) => {
    const response = await instance.get("VendorFreelancerPackage/GetAllPackage", {
      params: credentials,
    });
    return response.data;
  }
  
  export const activeDeactiveService = async (credentials: object) => {
    const response = await instance.post('AdminService/ActiveDeactiveService', credentials);
    return response.data;
  };

  export const activeDeactivePackage = async (credentials: object) => {
    const response = await instance.post('VendorFreelancerPackage/ActiveDeactivePackage', credentials);
    return response.data;
  };

  export const getVendorFreelancerServiceById = async (credentials: object) => {
    const response = await instance.get("VendorFreelancerService/GetById", {
      params: credentials,
    });
    return response.data;
  };

  export const getVendorFreelancerPackageById = async (credentials: object) => {
    const response = await instance.get("VendorFreelancerPackage/GetById", {
      params: credentials,
    });
    return response.data;
  };

  export const createVendorService = async (credentials: FormData) => {
    const response = await instance.post(
      "VendorFreelancerService/CreateUpdate",
      credentials,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  export const createVendorPackage = async (credentials: FormData) => {
    const response = await instance.post(
      "VendorFreelancerPackage/CreateUpdate",
      credentials,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  export const vendorActiveDeactiveService = async (credentials: object) => {
    const response = await instance.post('VendorFreelancerService/ActiveDeactiveService', credentials);
    return response.data;
  };