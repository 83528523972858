import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../../Theme";
import CButton from "../../../components/common/CButton";
import CustomAlert from "../../../components/common/CAlert";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
};

const OnSuccessSignUp: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });



    return (
        <>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
            <div className="main-div">
                <div className="strech-man-div">
                    <img src={Theme.icons.icn_auth_side} alt="auth-side-logo" className="auth-side-logo" />

                    <p className="event-planner">Lorem ipsum dolor sit amet consectetur. Lectus quis sapien urna ultrices augue at amet eu nunc.</p>
                </div>
                <div
                    className="auth-form-div d-flex align-items-center justify-content-center"
                    style={{ margin: "20px", position: 'relative' }}
                >
  
                    <div
                        className="auth-form-inner"
                        style={{
                            textAlign: "center",
                            margin: "20px"
                        }}
                    >
                        <img src={Theme.icons.icn_success_tick} />
                        <h2 className='user-name mt-2'>{t('success_modal_heading')}</h2>
                        <p className='login-des' style={{marginRight:'10rem', marginLeft:'10rem'}}>Lorem ipsum dolor sit amet consectetur. Semper suspendisse congue urna feugiat.</p>
                        <div className="w-100 d-flex justify-content-center mt-2">
                                        <CButton  disabled={false} className="mt-4 auth-btn w-50" onClick={() => navigate('/')}>{t("Continue")}</CButton>
                                    </div>
                   
                    </div>
                </div>
            </div>

        </>
    );
};

export default OnSuccessSignUp;
