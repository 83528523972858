import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import CInput from "../../components/common/CInput";
import AuthFrame from "../../components/ui/AuthFrame";
import { Theme } from "../../Theme";
import CButton from "../../components/common/CButton";
import SuccessModal from "../../components/common/SuccessModal";
import { useMutation } from "react-query";
import { forgot } from "../../services/auth";
import CustomAlert from "../../components/common/CAlert";
import { useTranslation } from "react-i18next";


interface ResponseType {
  message: string;
  // Add other properties as needed
}
const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false,
  });
  const [type, setType] = useState('');
  const [forgotData, setForgotData] = useState<{ emailOrPhoneNumber: string; response: ResponseType; type:string }>({
    emailOrPhoneNumber: '',
    response: { message: '' },
    type:''
  });

  const validateEmail = (value: string) => {
    setType('Email');
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
}
const validatePhoneNumber = (value: string) => {
    setType('PhoneNumber');
    return /^\+?\d{1,3}\s?\d{1,4}\s?\d{1,4}\s?\d{1,4}$/.test(value);
}

  const { mutate, isLoading } = useMutation(forgot, {
    onSuccess: (response) => {
      if (response.succeeded) {
        setForgotData(prev => ({ ...prev, response }));
        setIsSuccess(true);
      } else {
        setAlert({ message: response.message, type: 'error', visible: true });
      }
    },
    onError: (error: any) => {
      setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
    },
  });

  const onFinish = (values: { emailOrPhoneNumber: string }) => {
    const updatedData = { ...forgotData, emailOrPhoneNumber: values?.emailOrPhoneNumber,  type };
    setForgotData(updatedData);

    mutate({...values, type});
  };

  return (
    <>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
      <div className="main-div">
      <div className="strech-man-div">
                <img src={Theme.icons.icn_auth_side} alt="auth-side-logo" className="auth-side-logo" />
              
                    <p className="event-planner">Lorem ipsum dolor sit amet consectetur. Lectus quis sapien urna ultrices augue at amet eu nunc.</p>
                </div>
        <div className="auth-form-div d-flex align-items-center justify-content-center" style={{ margin: "20px" }}>
          <div className="auth-form-inner" style={{ textAlign: "left", margin: "20px", width: '43%' }}>
            <img src={Theme.icons.icn_main_logo} alt="logo" className="auth-logo" />
            <p className='login-heading mt-3 mb-0'>{t("forgot_password")}</p>
            <p className="login-des mt-1">{t('forgot_desc')}</p>
            <SuccessModal
              visible={isSuccess}
              message={forgotData?.response?.message}
              onOk={() => navigate('/otp', { state: { emailOrPhoneNumber: forgotData?.emailOrPhoneNumber, otpCode: forgotData.response.message.split('.')[1], type:forgotData?.type } })}
              onCancel={() => setIsSuccess(false)}
            />
            <Form
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
               <Form.Item
                                name="emailOrPhoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: t('email_validation'),
                                        validator: (_, value) => {
                                            // if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                                            //     return Promise.resolve();
                                            if (validateEmail(value) || validatePhoneNumber(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t('email_error'));
                                            }
                                        },
                                    },
                                ]}
                            >
                                <CInput
                                    label={t('email_address')}
                                    placeholder={t('email_placeholder')}
                                />
                            </Form.Item>
              <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn" htmlType="submit">{t("send")}</CButton>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
