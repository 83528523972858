import React, { useEffect, useState } from 'react'; 
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/Forgot';
import ResetPassword from './pages/Auth/Reset';
import PortalScreen from './pages/layout/PortalScreen';
import ManageUserList from './pages/MangeUsers/ManageInternalUser/ManageUserList';
import AddNewUser from './pages/MangeUsers/ManageInternalUser/AddNewUser';
import i18n from 'i18next';
import 'leaflet/dist/leaflet.css';
import ViewUser from './pages/MangeUsers/ManageInternalUser/ViewUser';
import ManageCustomerList from './pages/MangeUsers/ManageCustomer/ManageCustomerList';
import ManageFreelancerList from './pages/MangeUsers/ManageFreelancer/ManageFreelancerList';
import ViewFreelancer from './pages/MangeUsers/ManageFreelancer/ViewFreelancer';
import EventPolicyManagementList from './pages/Settings/EventPolicyMangement/EventPolicyManagemetList';
import AddEventType from './pages/Settings/EventPolicyMangement/Events/AddEvent';
import AddCity from './pages/Settings/EventPolicyMangement/Cities/AddCity';
import Otp from './pages/Auth/Otp';
import VendorOnboardingList from './pages/OnBoarding/Vendor/VendorOnboardingList';
import VendorOnBoarding from './pages/OnBoarding/Vendor/OnBoarding/VendorOnboarding';
import AddCategories from './pages/Settings/EventPolicyMangement/Categories/AddCategories';
import ViewVendor from './pages/OnBoarding/Vendor/ViewVendor';
import AddSubCategories from './pages/Settings/EventPolicyMangement/Categories/SubCategories/AddSubCategories';
import ServicesAndPackagesList from './pages/ServicesAndPackages/ServicesAndPackagesList';
import CreateService from './pages/ServicesAndPackages/CreateService';
import ViewService from './pages/ServicesAndPackages/ViewService';
import AddTermAndCondition from './pages/Settings/EventPolicyMangement/TermsAndConditions/AddTermAndConditions';
import AddCancellationPolicy from './pages/Settings/EventPolicyMangement/CancellationPolicy/AddCancellationPolicy';
import AddUpdateCountry from './pages/Settings/EventPolicyMangement/Country/AddUpdateCountry';
import CustomEventList from './pages/ManageBookings/CustomEvent/CustomEventList';
import CustomEventDetails from './pages/ManageBookings/CustomEvent/CustomEventDetails';
import ServiceBookingList from './pages/ManageBookings/ServiceBooking/ServiceBookingList';
import ViewServiceBooking from './pages/ManageBookings/ServiceBooking/ViewServiceBooking';
import AddBooking from './pages/ManageBookings/CustomEvent/AddBooking';
import Notifications from './pages/ManageNotifications/Notifications';
import ViewNotification from './pages/ManageNotifications/ViewNotification';
import ReportList from './pages/Reports/ReportList';
import PdfPreview from './pages/Reports/PdfPreview';
import ViewReport from './pages/Reports/ViewReport';
import ViewProfile from './pages/Settings/Profile/ViewProfile';
import PaymentLogs from './pages/PaymentLogs/PaymentLogs';
import ChangePassword from './pages/Settings/Profile/ChangePassword';
import SignUp from './pages/Auth/SignUp/SignUp';
import OnBoarding from './pages/Auth/OnBoarding/OnBoarding';
import OnSuccessSignUp from './pages/Auth/SignUp/OnSuccessSignUp';
import CreatePackage from './pages/ServicesAndPackages/CreatePackage';
import ViewPackage from './pages/ServicesAndPackages/ViewPackage';
import PromoCodesList from './pages/PromoCodes/PromoCodesList';

function App() {

  

useEffect(() => {
  const currentLanguage = i18n.language;
  document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
}, [i18n.language]);


useEffect(() => {
  const handleLanguageChange = (lng: string) => {
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  i18n.on('languageChanged', handleLanguageChange);

  // Clean up the listener when the component unmounts
  return () => {
    i18n.off('languageChanged', handleLanguageChange);
  };
}, []);

  return (
    <Routes>
    <Route index element={<Login />} />
    <Route path="signUp" element={<SignUp />} />
    <Route path="onSuccessSignUp" element={<OnSuccessSignUp />} />
    <Route path="onboarding" element={<OnBoarding />} />
    <Route path="home" element={<Home />} />
    <Route path="forgot" element={<ForgotPassword />} />
    <Route path="otp" element={<Otp />} />
    <Route path="reset" element={<ResetPassword />} />
     <Route path="jive" element={<PortalScreen />} >
    {/* <Route path="manageInternalUser" element={<ManageUserList />} /> */}
    <Route path="servicesAndPackagesList" element={<ServicesAndPackagesList />} />
    <Route path="createService" element={<CreateService />} />
    <Route path="viewService/:id" element={<ViewService />} />
    <Route path="notifications" element={<Notifications />} />
    <Route path="profile" element={<ViewProfile />} />
    <Route path="createUpdatePackage" element={<CreatePackage />} />
    <Route path="viewPackage/:id" element={<ViewPackage />} />
    <Route path="promoCodesList" element={<PromoCodesList />} />


    {/*<Route path="manageCustomer" element={<ManageCustomerList />} />
    <Route path="manageFreelancer" element={<ManageFreelancerList />} />
    <Route path="vendorList" element={<VendorOnboardingList />} />
    <Route path="eventPolicyManagementList" element={<EventPolicyManagementList />} />
    <Route path="servicesAndPackagesList" element={<ServicesAndPackagesList />} />
    <Route path="reports" element={<ReportList />} />
    <Route path="PackagesList/:id" element={<PackagesList />} />
    <Route path="serviceBooking" element={<ServiceBookingList />} />
    <Route path="addNewUser" element={<AddNewUser />} />
    <Route path="eventType/:type/:id" element={<AddEventType />} />
    <Route path="categories/:type/:id" element={<AddCategories />} />
    <Route path="categories/:ParentId/subcategories/:type/:id" element={<AddSubCategories />} />
    <Route path="term-and-conditions/:type/:id" element={<AddTermAndCondition />} />
    <Route path="cancellation-policy/:type/:id" element={<AddCancellationPolicy />} />
    <Route path="customEvent" element={<CustomEventList />} />
    <Route path="customEvent/:type/:id" element={<CustomEventDetails />} />
    <Route path="customEvent/:requestId/booking/:type/:id" element={<AddBooking />} />
    <Route path="notifications" element={<Notifications />} />
    <Route path="notification/:type/:id" element={<ViewNotification />} />
    <Route path="vendorOnboarding" element={<VendorOnBoarding />} />
    <Route path="country/:type/:id" element={<AddUpdateCountry />} /> 
    <Route path="country/view/:id" element={<AddUpdateCountry />} />
    <Route path="country/:countryId/cities/:type/:id" element={<AddCity />} />
    <Route path="createService" element={<CreateService />} />
    <Route path="viewUser" element={<ViewUser />} />
    <Route path="viewFreelancer" element={<ViewFreelancer />} />
    <Route path="viewVendor" element={<ViewVendor />} />
    <Route path="viewServiceBooking/:id" element={<ViewServiceBooking />} />
    <Route path="viewService/:id" element={<ViewService />} />
    <Route path="pdfPreview" element={<PdfPreview />} />
    <Route path="viewReport" element={<ViewReport />} />
    <Route path="profile" element={<ViewProfile />} />
    <Route path="paymentLogs" element={<PaymentLogs />} />
    <Route path="profile/changePassword" element={<ChangePassword />} /> */}
     </Route>
     
    
    </Routes>
  );
}

export default App;
