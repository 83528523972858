import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Row, Col, Upload, UploadProps } from 'antd';
import CButton from '../../../../components/common/CButton';
import CustomAlert from '../../../../components/common/CAlert';
import CInput from '../../../../components/common/CInput';
import { RcFile, UploadFile } from 'antd/es/upload';
import { SaveCertificationLincensesInfo } from '../../../../services/freelancer';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MultiImageUploader from '../../../../components/common/MultiImageUploader';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../Theme';

const getBase64 = (file: File, callback: (url: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(file);
};

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}

const CertificationsLicenses = forwardRef(({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }: any, ref) => {
    console.log('state in license===>>>>', state);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { Dragger } = Upload;
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [licenseFiles, setLicenseFiles] = useState<any[]>([]);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            form.submit(); 
        }
    }));

    form.setFieldsValue({
        certificates: uploadedFiles,
        licenses: licenseFiles
    });

    const { mutate, isLoading } = useMutation(SaveCertificationLincensesInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate('/jive/vendorList');
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleFileSelect = (files: File[]) => {
        console.log('file select===>>>', files);
        setUploadedFiles(files);
    };

    const handleLicenseSelect = (files: File[]) => {
        console.log('file select===>>>', files);
        setLicenseFiles(files);
    };
    


    const handleSave = async (values: any) => {
        const obj = {
            CommericalRegistrationNumber: values.CommericalRegistrationNumber,
            id: state.id,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab,
            vendorFreelancerCertifications: uploadedFiles.filter(file => !file.preview),
            ...(state?.userType === "Freelancer" && {
                VendorFreelancerLicenses: licenseFiles.filter(file => !file.preview)
            })

        }
        const formData = new FormData();
        const binaryFile = uploadedFiles.filter(file => file.preview);
        binaryFile.forEach((file) => {
            formData.append('certificates', file?.newFile as RcFile);
        });
        if(state?.userType === "Freelancer"){
            const binaryLicense = licenseFiles.filter(file => file.preview);
            binaryLicense.forEach((file) => {
                formData.append('licenses', file?.newFile as RcFile);
            }); 
        }
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);

    };


    return (
        <>

            <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                <div className='d-flex align-items-center justify-content-center' style={{ height: '72px', width: '72px', backgroundColor: '#EBE3F3', borderRadius: '50%' }}>
                    <img src={Theme.icons.icn_certificate_license} />
                </div>
                <p className='mb-0 user-name mt-2'>{t("Certifications & Licenses")}</p>
                <p className='admin service-desc '>Lorem ipsum dolor sit amet consectetur. Semper suspendisse congue urna feugiat.</p>
                <Form form={form} layout="vertical" onFinish={handleSave} initialValues={{ remember: true }} >


                    <Form.Item
                        name="CommericalRegistrationNumber"
                        rules={[{ required: true, message: 'Please enter the registration number' }]}
                        className='w-50'
                    >
                        <CInput label="Commercial Registration Number" placeholder="Enter Registration Number" />
                    </Form.Item>

<div className='d-flex justify-content-between w-100'>
<Form.Item
                        name="certificates"
                        rules={[{ required: true, message: 'Please enter the certificates' }]}
                    >
                        <label className="inputfield-label mt-2">{t("Commercial & License Documents")}</label>
                        <MultiImageUploader
                            type='documents'
                            setAlert={setAlert}
                            // storeImages={state?.vendorFreelancerCertifications.length !== 0 && state?.vendorFreelancerCertifications}
                            onFileSelected={handleFileSelect}
                        />
                    </Form.Item>
{state?.userType === "Freelancer" && (
                    <Form.Item
                        name="licenses"
                        rules={[{ required: true, message: 'Please enter the licenses' }]}
                        className='mx-3'
                    >
                        <label className="inputfield-label mt-2">{t("Freelancing Permit Document")}</label>
                        <MultiImageUploader
                            type='documents'
                            setAlert={setAlert}
                            // storeImages={state?.vendorFreelancerCertifications.length !== 0 && state?.vendorFreelancerCertifications}
                            onFileSelected={handleLicenseSelect}
                        />
                    </Form.Item>
                    )}
</div>

                    

                </Form>

                <CustomAlert
                    message={alert.message}
                    type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                    visible={alert.visible}
                    onClose={() => setAlert({ ...alert, visible: false })}
                    duration={3000}
                />

            </div>
        </>
    );
});

export default CertificationsLicenses;
