import React, { useEffect, useRef, useState } from 'react';

import { Layout, Menu, Steps, theme } from 'antd';
import { Theme } from '../../../Theme';
import CButton from '../../../components/common/CButton';
import VendorDetail from './component/VendorDetail';
import ServiceOffering from './component/ServiceOffering';
import PortfolioExperience from './component/PortfolioExperience';
import AvailabilityBookingDetail from './component/AvailabilityBookingDetail';
import CertificationsLicenses from './component/CertificationsLicenses';
import PaymentBillingInformation from './component/PaymentBillingInformation';
import SupportCommunication from './component/SupportCommunication';
import TermsAndConditions from './component/TermsAndConditions';
import { useLocation } from 'react-router-dom';
import { getVendorFreelancerById } from '../../../services/freelancer';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';





const OnBoarding: React.FC = () => {
    const [step, setStep] = useState(0);
    const location = useLocation();
    const { t } = useTranslation();
    const [vendorDetails, setVendorDetails] = useState({});
    const [maxVisitedTab, setMaxVisitedTab] = useState(0);
    const description = 'This is a description.';
    const {id} = location?.state;
    const vendorDetailFormRef = useRef<any>(null);
    const serviceOfferingFormRef = useRef<any>(null);
    const portfolioExperienceFormRef = useRef<any>(null);
    const availabilityBookingFormRef = useRef<any>(null);
    const certificationsLicensesFormRef = useRef<any>(null);
    const paymentBillingFormRef = useRef<any>(null);
    const SupportCommunicationFormRef = useRef<any>(null);
    const TermsAndConditionsFormRef = useRef<any>(null);

    console.log('max tab===>>>>', maxVisitedTab);
    const handleNextClick = () => {
        // Call submitForm on the current step form
        if (step === 0 && vendorDetailFormRef.current) {
            vendorDetailFormRef.current.submitForm();
        }else if (step === 1 && serviceOfferingFormRef.current) {
            serviceOfferingFormRef.current.submitForm();
        }else if (step === 2 && portfolioExperienceFormRef.current) {
            portfolioExperienceFormRef.current.submitForm();
        }else if (step === 3 && availabilityBookingFormRef.current) {
            availabilityBookingFormRef.current.submitForm();
        }else if (step === 4 && certificationsLicensesFormRef.current) {
            certificationsLicensesFormRef.current.submitForm();
        } else if (step === 5 && paymentBillingFormRef.current) {
            paymentBillingFormRef.current.submitForm();
        } else if (step === 6 && SupportCommunicationFormRef.current) {
            SupportCommunicationFormRef.current.submitForm();
        } else if (step === 7 && TermsAndConditionsFormRef.current) {
            TermsAndConditionsFormRef.current.submitForm();
        }
    };
    

    const handleMoveToNext = (data: any) => {
        console.log('data==+>>>', data);
        setStep(step+1);
    };

    const { data: vendorData, isLoading, error, refetch } = useQuery(
        ['VendorFreelancerById', id],
        () => getVendorFreelancerById({ Id: id }), // Replace with your fetch function
        {
            enabled: false, // Query only runs if the page was refreshed and activeId exists
            onSuccess: (data) => {
                console.log('vendor Freelancer data==>>>', data?.data);
                setVendorDetails(data.data);
                setMaxVisitedTab((data?.data as { tabNumber?: number })?.tabNumber  || 0 );
                if(data?.data?.tabNumber !== null){
                    const tabNo = data?.data?.tabNumber;
                    setStep( tabNo + 1);              
                }
            },
        }
    );

    useEffect(() => {
        refetch();
    },[])


    const onChange = (value: number) => {
        console.log('onChange:', value);
        setStep(value);
        // setCurrent(value);
      };
    return (
        <>
        <div className='d-flex h-100' style={{ backgroundColor: Theme.colors.white, height: '100vh' }}>
            <div style={{ width: '30%', backgroundColor: '#FEF7EC', height: '100vh', padding: '20px 40px', position:'fixed' }}>
                <img src={Theme.icons.icn_main_logo} alt="logo" className=" my-2 auth-logo" />
                <p className='vendor-side-heading'>Vendor Onboarding</p>
                <p className='inputfield-label'>Lorem ipsum dolor sit amet consectetur. Id pulvinar in non velit vestibulum.</p>

                <Steps
                    direction="vertical"
                    size="small"
                    className='mt-4'
                    current={step}  
        // onChange={onChange}
                    items={[
                        { title: 'Profile & Technical Information' },
                        { title: 'Service Offerings' },
                        { title: 'Portfolio & Experience' },
                        { title: 'Availability & Booking Details' },
                        { title: 'Certifications & Licenses' },
                        { title: 'Payment & Billing Information' },
                        { title: 'Support & Communication' },
                        { title: 'Terms & Condition Agreement' },
                    ]}
                />
            </div>
            <div className='d-flex justify-content-center align-items-center mt-5' style={{ backgroundColor: 'white', width:'100%', marginLeft:'30%', marginBottom:'8%' }}>


                {step === 0 && <VendorDetail ref={vendorDetailFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={0} maxVisitedTab={maxVisitedTab} />}
                {step === 1 && <ServiceOffering ref={serviceOfferingFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={1} maxVisitedTab={maxVisitedTab} />}
                {step === 2 && <PortfolioExperience  ref={portfolioExperienceFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={2} maxVisitedTab={maxVisitedTab} />}
                {step === 3 && <AvailabilityBookingDetail  ref={availabilityBookingFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={3} maxVisitedTab={maxVisitedTab} />}
                {step === 4 && <CertificationsLicenses  ref={certificationsLicensesFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={4} maxVisitedTab={maxVisitedTab} />}
                {step === 5 && <PaymentBillingInformation ref={paymentBillingFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={5} maxVisitedTab={maxVisitedTab} />}
                {step === 6 && <SupportCommunication ref={SupportCommunicationFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={6} maxVisitedTab={maxVisitedTab} />}
                {step === 7 && <TermsAndConditions ref={TermsAndConditionsFormRef} moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={false} selectedTab={7} maxVisitedTab={maxVisitedTab} />} 
                <div className='d-flex justify-content-around align-items-center px-5 p-2 bottom-buttons'>
                <CButton className='cancelBtn' style={{ width: 'min-content' }}>
                    Cancel
                </CButton>
                <CButton onClick={handleNextClick} >
                    <p className="m-0">{t("Next")}</p>
                    <img src={Theme.icons.icn_arrow_left} alt="icn_user_add" style={{ height: '20px' }} />
                </CButton>
            </div>
            </div>
           
        </div>
    </>
    );
};

export default OnBoarding;