import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CInput from '../../../../components/common/CInput';
import CButton from '../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { SavePortfolioExperienceInfo } from '../../../../services/freelancer';
import CustomAlert from '../../../../components/common/CAlert';
import CustomSelect from '../../../../components/common/CSelect';
import { getAllEvents } from '../../../../services/lov';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../../Theme';

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}

const PortfolioExperience = forwardRef(({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }: any, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState<string | undefined>(undefined);
    const [exit, setExit] = useState(false); 
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            form.submit(); 
        }
    }));

    const { data , error: countryError, isLoading: isCountryLoading } = useQuery('EventType', getAllEvents);

    const getData = () => {
        if (!data) return []; // Return an empty array if data is undefined
        return data.data.map((x: any) => ({
            value: x.id,
            label: x.name
        }));
    };
    const { mutate, isLoading } = useMutation(SavePortfolioExperienceInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const obj = {
            ...values,
            // id: state.id,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab
        }
        mutate(obj);
    }

    // const handleSaveAndExit = () => {
    //     setExit(true);
    //     form.submit();
    // };

    // useEffect(() => {
    //     if (isVendorDetailNotEmpty) {
    //         form.setFieldsValue(state);
    //     }
    // }, [])

    return (
        <>
<div className='d-flex flex-column align-items-center justify-content-center'>
            <div className='d-flex align-items-center justify-content-center' style={{height:'72px', width:'72px', backgroundColor: '#EBE3F3', borderRadius:'50%'}}>
<img src={Theme.icons.icn_portfolio_experience}/>
            </div>
            <p className='mb-0 user-name mt-2'>{t("Portfolio & Experience")}</p>
            <p className='admin service-desc '>Lorem ipsum dolor sit amet consectetur. Semper suspendisse congue urna feugiat.</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
                className='w-25'
            >
                        <Form.Item
                            name="portfolioLink"
                            rules={[{ required: true, message: t("Please enter the portfolio link") }]}
                        >
                            <CInput label={t("Portfolio Link")} placeholder={t("Enter Portfolio Link")} />
                        </Form.Item>
                        <Form.Item
                            name="socialMediaLinks"
                            rules={[{ required: true, message: 'Please enter the social media link' }]}
                        >
                            <CInput label={t("Social Media Link")} placeholder={t("Enter Social Media Link")} />
                        </Form.Item>

                    <Form.Item
                            name="eventTypeId"
                            rules={[{ required: true, message: 'Please choose a event type' }]}
                        >
                            <CustomSelect
                                label='Event Type'
                                options={getData()}
                                value={selectedEvent}
                                onChange={(value) => setSelectedEvent(value)}
                                placeholder="Choose Event Type"
                            />
                        </Form.Item>
                        <Form.Item
                            name="experience"
                            rules={[{ required: true, message: t("Please enter the experience") }]}
                        >
                            <CInput label={t("Experience")} placeholder={t("Enter Experience")} />
                        </Form.Item>
                  

            </Form >
            </div>
            <CustomAlert
                    message={alert.message}
                    type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                    visible={alert.visible}
                    onClose={() => setAlert({ ...alert, visible: false })}
                    duration={3000}
                />

        </>
    );
});

export default PortfolioExperience;
