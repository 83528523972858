import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CButton from '../../../../components/common/CButton';
import { useMutation } from 'react-query';
import { Theme } from '../../../../Theme';
import { SaveServiceDetailInfo } from '../../../../services/freelancer';
import CustomAlert from '../../../../components/common/CAlert';
import { Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../../../components/common/AlertModal';

const getBase64 = (img: RcFile, callback: (imageUrl: string | ArrayBuffer | null) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}

const VendorDetail = forwardRef(({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }: any, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);

    const handleChange = ({ fileList: newFileList }: { fileList: UploadFile[] }) => {
        setFileList(newFileList);

        const lastFile = newFileList.slice(-1)[0]?.originFileObj as RcFile;
        if (lastFile) {
            getBase64(lastFile, (url) => setImageUrl(url));
        }
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            form.submit(); 
        }
    }));


    const uploadProps: UploadProps = {
        action: "",
        onChange: handleChange,
        multiple: false,

    };

    form.setFieldsValue({
        profileImagePath: fileList,
    });




    // useEffect(() => {
    //     if (state.serviceTechnicalDescription !== null) {
    //         form.setFieldsValue({
    //             serviceTechnicalDescription: state?.serviceTechnicalDescription,
    //         });
    //     }
    //     if (state?.profileImagePath !== null) {
    //         setImageUrl(process.env.REACT_APP_S3_BUCKET_URL + state?.profileImagePath)
    //     }
    // }, [])

    const { mutate, isLoading } = useMutation(SaveServiceDetailInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate('/jive/vendorList');
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {

        const obj = {
            ServiceTechnicalDescription: values.serviceTechnicalDescription,
            // id: state.id,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab
        }

        const formData = new FormData();
        if (values.profileImagePath.length !== 0) {
            const file = fileList[0].originFileObj as RcFile;
            formData.append('ProfileImagePath', file);
        }
        formData.append('obj', JSON.stringify(obj));
        mutate(formData);
    }

   
    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <Form.Item
                        name="profileImagePath"
                        rules={[{ required: false, message: 'Please enter the profile image' }]}
                    >
                        <div style={{ backgroundColor: '#EAEAEA', height: '90px', width: '90px', borderRadius: '50%', position: 'relative' }}>
                            <Upload
                                {...uploadProps}
                            >
                                <div style={{ position: 'absolute', bottom: '0', right: '0' }} >
                                    <img src={Theme.icons.icn_camera} />
                                </div>
                            </Upload>
                            <img src={imageUrl ? imageUrl as string : Theme.icons.icn_placeholder} style={{ height: '90px', width: '90px', borderRadius: '50%' }} />
                        </div>
                    </Form.Item>
                    <p className='user-name mt-1'>{t("Vendor Profile")}</p>
                    <div>
                        <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px' }}>{t("Service Technical Requirements")}</label>
                        <Form.Item
                            name="serviceTechnicalDescription"
                            rules={[{ required: true, message: 'Please enter the requirement' }]}
                        >
                            <TextArea
                                showCount
                                autoSize={({ minRows: 6, maxRows: 8 })}
                                maxLength={600}
                                placeholder="Please enter your requirement"
                                style={{ height: 300, width: 500, borderRadius: '8px' }}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

            {/* <AlertModal
        visible={isVisible}
        message={t(`Are you sure you want to submit this`)}
        onCancel={() => setIsVisible(false)}
        onDelete={handleSave}
      /> */}

        </>
    );
});

export default VendorDetail;
