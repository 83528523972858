import { Col, Form, Input, Row, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// import { createAdminPackage, getAdminPackageById } from '../../services/service'
import { createVendorPackage } from '../../services/service'

import {
  getAllCancelationPolicy,
  getAllTermsCondition
} from '../../services/lov'
import { Theme } from '../../Theme'
import CInput from '../../components/common/CInput'
import CButton from '../../components/common/CButton'
import dayjs from 'dayjs'
import MultiImageUploader from '../../components/common/MultiImageUploader'
import SuccessModal from '../../components/common/SuccessModal'
import CustomAlert from '../../components/common/CAlert'
import { RcFile } from 'antd/es/upload'

const CreatePackage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const params = useParams()
  const { TextArea } = Input
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [imageFile, setImageFile] = useState<File[] | null>([])

  const [packageDetails, setPackageDetails] = useState<{ [key: string]: any }>(
    {}
  )
  const [termsHtml, setTermsHtml] = useState('')
  const [cancelationHtml, setCancelationHtml] = useState('')
  const [service, setService] = useState<{ [key: string]: any }>({})
//   const { data, isLoading, error, refetch } = useQuery(
//     ['packageById', params?.id],
//     () => getAdminPackageById({ Id: params?.id })
//   )
  const handleFileSelect = (files: File[]) => {
    console.log('file select===>>>', files)
    setImageFile(files)
  }

  const {
    data: termsAndConditionData,
    error: countryError,
    isLoading: isCountryLoading
  } = useQuery('termsCondition', getAllTermsCondition)

  const {
    data: cancelationPolicyData,
    error: cancelationPolicyError,
    isLoading: cancelationPolicyLoading
  } = useQuery('cancelationPolicy', getAllCancelationPolicy)

  const cancelationPolicy = cancelationPolicyData?.data?.filter(
    (x: any) => x.type === 'Vendor'
  )

  useEffect(() => {
    if(termsAndConditionData!= undefined){
        setTermsHtml(termsAndConditionData?.data[0]?.description);
    }
    if(cancelationPolicy!= undefined){
        setCancelationHtml(cancelationPolicy[0]?.description);
    }
  }, [termsAndConditionData,cancelationPolicy]);

  const onTimeChange: (
    time: any,
    timeString: string | string[],
    type: 'start_time' | 'end_time'
  ) => void = (time, timeString, type) => {
    console.log(time, timeString)

    const selectedTime = Array.isArray(timeString)
      ? timeString.join(', ')
      : timeString
    form.setFieldsValue({
      [type === 'start_time' ? 'packageStartTime' : 'packageEndTime']:
        selectedTime
    })
  }
  form.setFieldsValue({
    images: record ? record?.packageImages : imageFile,
//     termandCondition: termsAndConditionData?.data[0]?.description,
//     privacyPolicy: cancelationPolicy && cancelationPolicy[0]?.description,
//     currencyCode: 'SAR'
  })

  if (location.state) {
    
    var { record, formType, serviceDetails } = location.state
    console.log('location.state', location.state)
    let sd = location.state.serviceDetails
    let dict = {
      categoryName: sd.categoryName,
      vendorName: sd.vendorFreelancerName,
      serviceName: sd.serviceName,
      serviceLocation:sd.serviceLocation
    }
    form.setFieldsValue({
        currencyCode: 'SAR',
        termandCondition:termsHtml,
        privacyPolicy:cancelationHtml,
        // images: record ? record?.packageImages : imageFile,
        ...dict
    })
    if(record != undefined){
        form.setFieldsValue({...record,
            currencyCode: 'SAR'
        })
    }
    // setService(dict);
  }

  const { mutate, isLoading: submitLoading } = useMutation(
    createVendorPackage,
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsSuccess(true)
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      serviceId: serviceDetails.id,
      vendorId: serviceDetails.vendorFreelancerId
    //   ...service
    }
    const { images, ...newObj } = obj

    const formData = new FormData()

    if (record !== undefined) {
      const finalObj = {
        ...newObj,
        id:record.id,
        packageImages: images.filter((file: any) => !file.preview)
      }
      const binaryFile = imageFile?.filter((file: any) => file.preview)
      console.log('finalObj::==>>>', finalObj)
      binaryFile?.forEach((file: any) => {
        formData.append('packageImages', file?.newFile as RcFile)
      })
      formData.append('obj', JSON.stringify(finalObj))
    } else {
      images.forEach((file: any) => {
        formData.append('packageImages', file?.newFile as RcFile)
      })

      formData.append('obj', JSON.stringify(obj))
    }
    mutate(formData)
    
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2 user-name'>
            {formType == 'create' ? 'Create New Package' : 'Update Package'}
          </p>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSave}
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name'>{`Package Information`}</p>
          <>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='packageName'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the package name')
                    }
                  ]}
                >
                  <CInput
                    label={t('Package Name')}
                    placeholder={t('Enter Package Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='vendorName'
                  rules={[
                    { required: true, message: t('Please choose a vendor') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Vendor Name')}
                    placeholder={t('Enter Vendor Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceName'
                  rules={[
                    { required: true, message: t('Please choose a service') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Service Name')}
                    placeholder={t('Enter Service Name')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='categoryName'
                  rules={[
                    { required: true, message: t('Please choose a category') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Category Name')}
                    placeholder={t('Enter Category Name')}
                    value={serviceDetails.categoryName}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='packagePrice'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the package price')
                    }
                  ]}
                >
                  <CInput
                    label={t('Package Price')}
                    placeholder={t('Enter Package Price')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='currencyCode'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a currency')
                    }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Currency')}
                    placeholder={t('Enter Currency')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='packageStartTime'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the start time')
                    }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Package Start Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.packageStartTime
                        ? dayjs(record?.packageStartTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'start_time')
                    }
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='packageEndTime'
                  rules={[
                    { required: true, message: t('Please enter the end time') }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Package End Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.packageEndTime
                        ? dayjs(record?.packageEndTime, 'h:mm A')
                        : null
                    }
                    use12Hours
                    format='h:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'end_time')
                    }
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='providerContact'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the provider contact')
                    }
                  ]}
                >
                  <CInput
                    label={t('Provider Contact')}
                    placeholder={t('Enter Provider Contact')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceLocation'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a package location')
                    }
                  ]}
                >
                  <CInput
                    disabled
                    label={t('Package Location')}
                    placeholder={t('Enter Location')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Terms & Conditions')}
                </label>
                <Form.Item
                  name='termandCondition'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the terms and conditions')
                    }
                  ]}
                >
                   <div 
                    className='terms-html-content'
                    style={{ height: 200, overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{ __html: termsHtml ? termsHtml :'' }}
                />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Cancelation Policy')}
                </label>
                <Form.Item
                  name='privacyPolicy'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the cancelation policy')
                    }
                  ]}
                >
                  <div 
                    className='terms-html-content'
                    style={{ height: 200, overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{ __html: cancelationHtml? cancelationHtml:''  }}
                />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Feature')}
                </label>
                <Form.Item
                  name='feature'
                  rules={[
                    { required: true, message: t('Please enter the features') }
                  ]}
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder={t('Enter Feature')}
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Description')}
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the description')
                    }
                  ]}
                >
                  <TextArea
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder='Write some description'
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-6'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Package Images')}
                </label>
                <Form.Item
                  name='images'
                  rules={[
                    { required: true, message: t('Please upload the images') }
                  ]}
                >
                  <MultiImageUploader
                    type='images'
                    setAlert={setAlert}
                    storeImages={
                      record !== undefined && formType == 'create'
                        ? []
                        : record?.packageImages
                    }
                    onFileSelected={handleFileSelect}
                  />
                </Form.Item>
              </div>
            </div>
              <Row gutter={8} style={{ marginTop: '15px' }}>
                <Col span={4}>
                  <CButton className='cancelBtn' onClick={() => navigate(-1)}>
                    Cancel
                  </CButton>
                </Col>
                <Col span={4}>
                  <CButton
                    loading={submitLoading}
                    style={{ width: '100%' }}
                    htmlType='submit'
                  >
                    Save
                  </CButton>
                </Col>
              </Row>
          </>
        </Form>
        <SuccessModal
          visible={isSuccess}
          message={`${
            record && formType != 'create'
              ? 'Package has been updated successfully'
              : 'Package has been created successfully'
          }
          
          `}
          onOk={() => navigate(-1)}
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
    </>
  )
}

export default CreatePackage

