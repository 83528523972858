import instance from "./api_instance";

export const CreateVendorFreelancerUser = async (credentials: object) => {
    const response = await instance.post(
      "VendorFreelancerUser/CreateVendorFreelancerUser",
      credentials
    );
    return response.data;
  };

  export const GetMyProfileOfVendorFreelancer = async (credentials: object) => {
    const response = await instance.get(
      "VendorFreelancerAccount/GetMyProfile",
      {}
    );
    return response.data;
  };
  