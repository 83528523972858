import React, { useState, useEffect } from 'react';
import CInput from './CInput';
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface Country {
  name: string;
  countryCode: string;
  currencyCode: string;
  prefixCode: string;
  flagImagePath: string;
  id: number;
}

interface PhoneInputProps {
  countries: Country[];
  onPhoneNumberChange: (phoneDetails: { phoneNumber: string; countryId: number; prefix: string }) => void;
  label?: string;
  initialValue?: string;
  isDisabled?: boolean;
}

const Phone: React.FC<PhoneInputProps> = ({ countries, onPhoneNumberChange, label, initialValue, isDisabled }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    // Extract prefix and phone number from initial value
    if (initialValue) {
      const prefix = countries.find(country => initialValue.startsWith(country.prefixCode))?.prefixCode || '';
      const number = initialValue.replace(prefix, '');
      setSelectedCountry(countries.find(country => country.prefixCode === prefix) || countries[0]);
      setPhoneNumber(number);
    }
  }, [initialValue, countries]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = countries.find(country => country.prefixCode === event.target.value);
    if (selected) {
      setSelectedCountry(selected);
    }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, ''); // Removes any non-numeric characters
    // Limit the length to 13 characters
    const limitedValue = numericValue.slice(0, 13);
    setPhoneNumber(limitedValue);
    onPhoneNumberChange({
      phoneNumber: limitedValue,
      countryId: selectedCountry.id,
      prefix: selectedCountry.prefixCode
    });
  };

  useEffect(() => {
    onPhoneNumberChange({
      phoneNumber,
      countryId: selectedCountry.id,
      prefix: selectedCountry.prefixCode
    });
  }, [selectedCountry]);

  return (
    <div>
      {label && <label className='inputfield-label' style={{ display: 'block', marginBottom: '5px', textAlign:'start' }}>{label}</label>}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div className='inputfield' style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
          <img
            src={process.env.REACT_APP_S3_BUCKET_URL+selectedCountry?.flagImagePath}
            alt={selectedCountry?.name}
            style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius:'50%' }}
          />
          <select disabled={isDisabled} value={selectedCountry?.prefixCode} onChange={handleCountryChange} style={{ border: 'none', outline: 'none' }}>
            {countries?.map(country => (
              <option key={country.id} value={country.prefixCode}>
                {country.prefixCode}
              </option>
            ))}
          </select>
        </div>
        <div className='phone-no-input'>
          <CInput
          disabled={isDisabled}
            type="number"
            maxLength={13}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
          />
        </div>
      </div>
    </div>
  );
};

export default Phone;
