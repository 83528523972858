import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CInput from '../../../../components/common/CInput';
import CButton from '../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { getCountries } from '../../../../services/manageUser';
import { SavePaymentDetailInfo } from '../../../../services/freelancer';
import CustomAlert from '../../../../components/common/CAlert';
import CustomSelect from '../../../../components/common/CSelect';
import { getCityById } from '../../../../services/lov';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../../Theme';

interface PaymentBillingInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}

const PaymentBillingInformation = forwardRef(({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }: any, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [selectedCountry, seSelectedCountry] = useState();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            form.submit(); 
        }
    }));

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            console.log('data present');
            form.setFieldsValue(state);
            seSelectedCountry(state.countryId);
        } else {
            console.log('data not present');
        }
    }, [])

    const { data, error: countryError, isLoading: isCountryLoading } = useQuery('countries', getCountries);

    const { data: cityData, error: cityError, isLoading: isCityLoading } = useQuery(['cityById', 1],() =>  getCityById({CountryId : 1}));


    const getData = (val: any) => {
        if (!val) return []; // Return an empty array if data is undefined
        return val.data.map((x: any) => ({
            value: x.id,
            label: x.name ? x.name : x.cityName
        }));
    };

    const { mutate, isLoading } = useMutation(SavePaymentDetailInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate('/jive/vendorList');
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const cityName = cityData?.data?.filter((x:any) => x.id === values.cityId);
        const countryName = data?.data?.filter((x:any) => x.id === values.countryId);
        const obj = {
            ...values,
            id:state?.id ,
            cityName: cityName[0]?.cityName,
            countryName: countryName[0]?.name,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab,
        }
        mutate(obj);
    }

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };

    return (
        <>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='d-flex align-items-center justify-content-center' style={{ height: '72px', width: '72px', backgroundColor: '#EBE3F3', borderRadius: '50%' }}>
                    <img src={Theme.icons.icn_payment_booking} />
                </div>
                <p className='mb-0 user-name mt-2'>{t("Payment & Billing Information")}</p>
                <p className='admin service-desc '>Lorem ipsum dolor sit amet consectetur. Semper suspendisse congue urna feugiat.</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
                className='w-25'
            >
                <Form.Item
                    name="bankName"
                    rules={[{ required: true, message: t("Please enter the bank name") }]}
                >
                    <CInput label={t("Bank Name")} placeholder={t("Enter Bank Name")} />
                </Form.Item>
                <Form.Item
                    name="accountHolderName"
                    rules={[{ required: true, message: t("Please enter the account holder name") }]}
                >
                    <CInput label={t("Account Holder Name")} placeholder={t("Enter Account Holder Name")} />
                </Form.Item>
                <Form.Item
                    name="accountNumber"
                    rules={[{ required: true, message: t("Please enter the account number") }]}
                >
                    <CInput label={t("Account Number")} placeholder={t("Enter Account Number")} />
                </Form.Item>
                <Form.Item
                    name="countryId"
                    rules={[{ required: true, message: t("Please choose a country") }]}
                >
                    <CustomSelect
                                label='Select Country'
                                options={getData(data)}
                                placeholder={t("Choose Country")}
                            />
                </Form.Item>
                <Form.Item
                    name="cityId"
                    rules={[{ required: true, message: t("Please choose a city") }]}
                >
                    <CustomSelect
                                label='Select City'
                                options={getData(cityData)}
                                // value={selectedRole}
                                placeholder={t("Choose City")}
                            />
                </Form.Item>
              

            </Form >
            </div>

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
});

export default PaymentBillingInformation;
