import React, { useState } from 'react';
import { Layout, Tag } from 'antd';
import { Theme } from '../../Theme';
import Header from '../../components/common/Header';
import MenuWrapper from '../../components/ui/MenuWrapper';
import { Outlet, useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;

const getImage = (imgPath: string, selectedImgPath?: string, isSelected?: boolean) => (
  <img
    src={isSelected && selectedImgPath ? selectedImgPath : imgPath}
    style={{ height: '20px' }}
  />
);
const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('servicesPackages');
  const [highlightStyle, setHighlightStyle] = useState({ top: 0, height: 0 });
  const [openKeys, setOpenKeys] = useState<string[]>([]); // Added to track open submenus
  const navigate = useNavigate();

  const items2 = [
    {
      key: 'home',
      icon: getImage(Theme.icons.icn_home),
      label: 'Home',
      path: '/home', // Add path for navigation
    },
    {
      key: 'manageBooking',
      icon: getImage(Theme.icons.icn_manage_booking),
      label: 'Manage Booking',
      path: '/serviceBooking', // Add path for navigation
    },
    {
      key: 'servicesPackages',
      icon: getImage(Theme.icons.icn_services_packages, Theme.icons.icn_white_services_packages, selectedKey === 'servicesPackages'),
      label: 'Services & Packages',
      path: '/servicesAndPackagesList',
    },
    {
      key: 'paymentLogs',
      icon: getImage(Theme.icons.icn_payment_logs, Theme.icons.icn_white_payment_logs, selectedKey === 'paymentLogs'),
      label: 'Payments',
      path: '/paymentLogs',
    },
    // {
    //   key: 'chats',
    //   icon: getImage(Theme.icons.icn_reports, Theme.icons.icn_white_reports, selectedKey === 'reports'),
    //   label: 'Chats',
    //   path: '/',
    // },
    {
      key: 'promoCode',
      icon: getImage(Theme.icons.icn_promo, Theme.icons.icn_white_promo, selectedKey === 'promoCode'),
      label: 'Promo Code',
      path: '/promoCodesList',
    },
    {
      key: 'reports',
      icon: getImage(Theme.icons.icn_reports, Theme.icons.icn_white_reports, selectedKey === 'reports'),
      label: 'Reports',
      path: '/reports',
    },
    {
      key: 'notifications',
      icon: getImage(Theme.icons.icn_notification),
      label: 'Notifications',
      path: '/notifications'
    },
    {
      key: 'settings',
      icon: getImage(Theme.icons.icn_settings),
      label: 'Settings',
      path: '/profile'
    },
  ];
  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    localStorage.removeItem('active');
    // navigate(`/jive${e.path}`);

    // Find the menu item that was clicked
    const menuItem = items2
      .flatMap((item) => ([item]))
      .find((item) => item.key === e.key);

    // // Navigate to the path of the clicked menu item
    if (menuItem?.path) {
      navigate(`/jive${menuItem.path}`);
    }
  };

  const onLogOut = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider width={260} style={{ background: Theme.colors.white }}>
        <div className='mt-4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img alt="logo" src={Theme.icons.icn_main_logo} />
        </div>
        <div className='d-flex flex-column justify-content-between' style={{ height: '90%' }}>
          <MenuWrapper
            items={items2}
            selectedKey={selectedKey}
            highlightStyle={highlightStyle}
            onClick={handleMenuClick}
            openKeys={openKeys} // Pass openKeys to MenuWrapper
            setOpenKeys={setOpenKeys} // Pass setter to MenuWrapper
          />
          <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
            <Tag className='status-listing w-75' style={{ borderColor: '#F55666' }} onClick={onLogOut}>
              <img src={Theme.icons.icn_logout} style={{ height: '20px' }} />
              <p className='m-0 logout mx-2'>Logout</p>
            </Tag>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: '24px',
            margin: 0,
            backgroundColor: Theme.colors.contentColor,
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};


export default PortalScreen;
