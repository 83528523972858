import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Theme } from "../../Theme"
import { get } from "../../services/api/apiService"
import { useQuery } from "react-query"
import CInput from "../../components/common/CInput"
import _ from 'lodash'
import CTable from "../../components/common/CTable"
import dayjs from 'dayjs';


const Notifications : React.FC =  ()=> {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Title'),
      dataIndex: 'enTitle',
      key: 'title'
    },
    {
      title: t('Date'),
      key: 'date',
      render: (text, record) => (
        <p>{dayjs(record.notificationDate)?.format('DD.MM.YYYY, HH:mm')}</p>
    )
    },
    {
      title: t('Description'),
      dataIndex: 'enDescription',
      key: 'description'
    },
    
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px',cursor: 'pointer' }}
            onClick={() =>
              navigate(`/jive/notification/view/${record?.id}`, {
                state: {record:record}
                
              })
            }
          />
        </div>
      ),
      key: 'action'
    }
  ]

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const { data, error, isLoading } = useQuery(
    [pageNumber,pageSize,search],
    () =>
      get('AdminNotification/GetAllNotification', {
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search
      }),
    {
      onError: (err: any) => {}
    }
  )
    return (
        <>
        <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{t('Notifications List')}</p>
        <div className='d-flex justify-content-between'>
          <CInput
            prefix={
              <img
                alt=''
                src={Theme.icons.icn_search}
                style={{ height: '16px' }}
              />
            }
            className='searchInput'
            placeholder={t('Search')}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
        </>
    )
}

export default Notifications;